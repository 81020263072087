import './DefaultLayout.less'

import { Col, Drawer, Layout, Row } from 'antd'
import { FC, Suspense } from 'react'
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom'
import { useTranslationsQuery, WebsiteComponent } from 'src/graphql'

import { useApp } from '../app'
import { useLocale } from '../app/I18n'
import { TopMenu } from '../menu/Navigation'
import { Content } from './Content'
import { Footer } from './Footer'
import { Header } from './Header'

type ContentProps = ComponentPageContactUs | ComponentPageHome | ComponentPageAbout

const DefaultLayout: FC = () => {
  const { locale } = useLocale()
  const {
    burger: { opened, toggle },
  } = useApp()
  const { pathname } = useLocation()
  const { data: translationData } = useTranslationsQuery({ variables: { locale } })
  const header: Maybe<EntryFragment>[] = translationData?.translation?.data?.attributes?.entry.filter(it => it?.key?.startsWith('header.')) ?? []

  return (
    <Layout>
      <Layout.Header style={{ lineHeight: 'unset', height: 'unset' }}>
        <Content>
          <Header data={header} />
        </Content>
      </Layout.Header>
      <Row justify={'center'} align={'middle'} style={{ background: '#fff' }}>
        <Col span={24}>
          <Content centered={false}>
            <Row justify={'space-between'} align={'middle'}>
              <Col span={24}>
                <TopMenu />
              </Col>
            </Row>
          </Content>
        </Col>
      </Row>
      <Layout.Content>
        <WebsiteComponent variables={{ locale }}>
          {({ data }) => {
            function filterByPathname<T extends ContentProps>(data: T[] = []): T | null {
              return (data.find(it => it?.pathname === pathname) ?? null) as T
            }

            return (
              <Suspense fallback={null}>
                <Outlet context={filterByPathname(data?.website?.data?.attributes?.content as ContentProps[])} />
              </Suspense>
            )
          }}
        </WebsiteComponent>
      </Layout.Content>
      <Layout.Footer>
        <Content style={{ padding: '40px 24px' }} centered={false}>
          <Footer data={header} />
        </Content>
      </Layout.Footer>
      <Drawer width={'50%'} height={'100%'} onClose={toggle} visible={opened} />
      <ScrollRestoration />
    </Layout>
  )
}

export { DefaultLayout }
