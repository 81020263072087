import './Content.less'

import { Layout } from 'antd'
import { CSSProperties, FC, PropsWithChildren } from 'react'

type ContentProps = { fixed: boolean; centered: boolean; style: CSSProperties }

const Content: FC<PropsWithChildren<Partial<ContentProps>>> = ({ fixed = true, centered = true, style, children }) => (
  <Layout.Content
    style={style}
    className={[fixed ? 'ant-layout-content-fixed' : '']
      .concat(centered ? 'ant-layout-content-centered' : '')
      .filter(Boolean)
      .join(' ')}
  >
    {children}
  </Layout.Content>
)

export { Content }
