import './Navigation.less'

import { Col, Menu, MenuProps, Row, Select } from 'antd'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useMenuQuery } from 'src/graphql'

import { Locale, useLocale } from '../app/I18n'

const TopMenu: FC<MenuProps> = props => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { locale, setLocale } = useLocale()
  const { data } = useMenuQuery({ variables: { filters: { slug: { eq: 'top-navigation' } } } })

  return (
    <Row gutter={24} justify={'space-between'} align={'middle'}>
      <Col span={18}>
        <Menu
          theme={'light'}
          mode={'horizontal'}
          items={data?.menusMenus?.data?.[0]?.attributes?.items?.data
            ?.map(it => it.attributes)
            .map(it => ({ label: formatMessage({ id: it?.title }), key: `${it?.url}`, onClick: () => navigate(`${it?.url}`) }))}
          {...props}
        />
      </Col>
      <Col>
        <Select<Locale>
          size={'small'}
          onSelect={(value: Locale) => setLocale(value)}
          defaultValue={locale}
          bordered={false}
          options={[
            { label: 'Укр', value: 'uk' },
            { label: 'Рус', value: 'ru' },
          ]}
        />
      </Col>
    </Row>
  )
}

export { TopMenu }
