import { FacebookFilled, InstagramFilled } from '@ant-design/icons'
import { Card, Col, List, Row, Typography } from 'antd'
import { FC } from 'react'

const socials = [
  { icon: FacebookFilled, url: 'https://www.facebook.com/val.kvasha' },
  { icon: InstagramFilled, url: 'https://www.instagram.com/valeriia_kvasha' },
]

const Footer: FC<{ data: Maybe<EntryFragment>[] }> = ({ data = [] }) => {
  const phone = data?.find(it => it?.key === 'header.phone')?.value
  return (
    <Row align={'middle'} justify={'space-between'}>
      <Col>
        <Card.Meta title={data?.find(it => it?.key === 'header.title')?.value} description={<a href={`tel:${phone}`}>{phone}</a>} />
      </Col>
      <Col>
        <List
          rowKey={'url'}
          dataSource={socials}
          grid={{ gutter: 16, column: 2 }}
          renderItem={({ icon: Icon, url }) => (
            <List.Item>
              <Typography.Link style={{ fontSize: 32 }} target={'_blank'} href={url}>
                <Icon size={32} />
              </Typography.Link>
            </List.Item>
          )}
        />
      </Col>
    </Row>
  )
}

export { Footer }
